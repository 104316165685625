<template>
  <div class="container-fluid project-menu">
    <v-subheader @click="goHome" class="pointer project-menu_subheader">
      <v-icon color="orange">
        mdi-arrow-left
      </v-icon>
        Список проектов
    </v-subheader>
    <v-list dense>
      <v-text-field
        class="search"
        v-model="search"
        label="Поиск"
      ></v-text-field>

      <!-- ошибка -->
      <v-list-item-group v-if="errorProjectList !== null" class="apollo-msg" color="orange lighten-1">
        Ошибка :(
      </v-list-item-group>

      <!-- загрузка -->
      <v-list-item-group v-else-if="loadingProjectList" class="apollo-msg" color="orange lighten-1">
        Загрузка...
      </v-list-item-group>

      <!-- данные -->
      <v-list-item-group v-else color="orange darken-1" v-model="activeIndex">
        <v-list-item
          v-for="(project, projectIndex) in projectList"
          :key="'project_' + projectIndex"
          :class="{'v-list-item--active': project.codename === prjCodename}"
          @click="selectProject(project)"
          :active="project.codename === prjCodename"
        >
          <!-- <div class="project-menu__item"> -->
            <v-list-item-title>
              {{ project.name }}
            </v-list-item-title>
          <!-- </div> -->
        </v-list-item>
        <VueVisibilityTrigger @scrolledIn="getNextPageLeftMenu($event)" />
      </v-list-item-group>
    </v-list>
  </div>
</template>

<script>
// import {eventBus} from '@/main.js'
import VueVisibilityTrigger from 'vue-visibility-trigger'

export default {
  name: 'project-menu',
  props: ['projectList', 'loadingProjectList', 'errorProjectList', 'prjCodename'],
  components: {
    VueVisibilityTrigger
  },
  data () {
    return {
      search: '',
      searchTimer: null,
      searchDelay: 500,
      activeIndex: -1, // какой элемент подсветить как активный
      init: true
    }
  },

  watch: {
    search (newVal) {
      if (this.searchTimer !== null) {
        clearTimeout(this.searchTimer)
      }
      this.searchTimer = setTimeout(() => {
        this.$emit('changeSearch', newVal)
      }, this.searchDelay)
    },

    projectList (newVal) { // в первый раз при загрузке списка проектов устанавливает activeIndex на нужный элемент после чего больше не срабатывает
      if (this.init && newVal && newVal.length) {
        this.activeIndex = this.projectList.findIndex(item => {
          return item.codename === this.prjCodename
        })
        this.$emit('getNextPageLeftMenu')
        this.init = false
      }
    }
  },

  methods: {
    goHome () {
      this.$router.push({name: 'home'})
    },

    selectProject (project) {
      this.$emit('selectProject', project)
      this.$router.push({name: 'project-maps', params: {prjCodename: project.codename}})
    },

    getNextPageLeftMenu (e) {
      if (e) {
        this.$emit('getNextPageLeftMenu')
      }
    }
  }
}
</script>

<style lang="scss">
.project-menu {
  padding: 0 0 10px 0;
  height: calc(100vh - 74px);
  overflow-y: auto;
  overflow-x: hidden;
  &_subheader {
    transition: all 0.3s;
    margin-left: -5px;
  }
  &_subheader:hover {
    padding-left: 10px;
    color: black;
  }
}

.apollo-msg,
.search {
  padding: 0 15px;  
}
</style>

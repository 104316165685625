<template>
  <div class="container-fluid project-main">
    <v-row>
      <v-col class="shadow project-menu-wrapper d-none d-md-block" md="2">
        <ProjectMenuVue
          :projectList="projectList"
          :loadingProjectList="loadingProjectList"
          :errorProjectList="errorProjectList"
          :prjCodename="prjCodename"
          @changeSearch="changeSearch($event)"
          @selectProject="selectProject($event)"
          @getNextPageLeftMenu="getNextPageLeftMenu"
        ></ProjectMenuVue>
      </v-col>
      <v-col class="project-maps-wrapper" md="10">
        <ProjectMapsVue :prjCodename="prjCodename" :selectedPrj="selectedPrj"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
// import {eventBus} from '@/main.js'
import ProjectMapsVue from './ProjectMapsVue'
import ProjectMenuVue from './ProjectMenuVue'

export default {
  name: 'project-main',
  props: ['prjCodename'],
  components: {
    ProjectMapsVue,
    ProjectMenuVue
  },

  data () {
    return {
      projectList: [],
      loadingProjectList: 0,
      inputTimer: null,
      search: '',
      errorProjectList: null,
      selectedPrj: null,
      hasMoreProjects: true,
      offset: 0
    }
  },

  computed: {
    isGraphToken () {
      return this.$store.getters.IS_GRAPH_TOKEN
    }
  },

  apollo: {
    projectList: {
      query: require('@/graphql/Projects.gql'),
      variables() {
        return {
          search: this.search
        }
      },
      update (data) {
        return data.project
      },
      loadingKey: 'loadingProjectList',
      error(err) {this.errorProjectList = err}
    }
  },

  async mounted () {
    if (this.prjCodename) { // при инициализации найти проект по codename из url
      try {
        let prj = await this.$apollo.query({
          query: require('@/graphql/ProjectByCodename.gql'),
          variables: {
            codename: this.prjCodename
          }
        })
        this.selectedPrj = prj.data.project[0]
      } catch (error) {
        console.error('can not set project with codename ' + this.prjCodename, error)
      }
    }
  },

  methods: {
    selectProject (prj) {
      this.selectedPrj = prj
    },

    changeSearch (e) {
      this.hasMoreProjects = true
      this.offset = 0
      this.search = e
    },

    getNextPageLeftMenu () {
      if (!this.hasMoreProjects) {
        return
      }
      this.offset += 20
      this.loadNextPage = true
      this.$apollo.queries.projectList.fetchMore({
        variables: {
          offset: this.offset,
          search: this.search
        },
        updateQuery: (previosResult, {fetchMoreResult}) => {
          this.loadNextPage = false
          this.hasMoreProjects = fetchMoreResult.project.length === 20 // хреновая проверка. Получаем по 20. Если получили меньше 20 получется что больше нет результатов
          let newResult = { project: [...previosResult.project, ...fetchMoreResult.project] }
          return newResult
        }
      })
    }
  }
}
</script>

<style lang="scss">
.project-main {
  .project-maps-wrapper {
    padding-top: 10px;
    position: relative;
    z-index: 5;
  }
  .project-menu-wrapper {
    margin-top: 12px;
    padding: 0;
  }
}
</style>

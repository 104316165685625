<template>
  <div class="text-center">
    <v-dialog
      v-model="dialog"
      width="500"
    >

      <v-card>
        <v-card-title class="headline grey lighten-2">
          Учетные данные
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-text-field label="логин" :value="login" disabled></v-text-field>
              <v-icon class="copy-btn mt-6" @click="copy('login')">mdi-content-copy</v-icon>
            </v-row>

            <v-spacer></v-spacer>

            <v-row>
              <v-text-field label="пароль" :value="password" disabled></v-text-field>
              <v-icon class="copy-btn mt-6" @click="copy('password')">mdi-content-copy</v-icon>
            </v-row>
          </v-container>
        </v-card-text>
      </v-card>

    </v-dialog>
  </div>
</template>

<script>
export default {
  name: 'ngw-login-data',
  props: ['password', 'login', 'open'],

  data () {
    return {
      dialog: false
    }
  },

  watch: {
    dialog (newVal) {
      if (!newVal) {
        this.$emit('close')
      }
    },

    open (newVal) {
      if (newVal) {
        this.dialog = true
      }
    }
  },

  methods: {
    copy (val) {
      navigator.clipboard.writeText(val === 'login' ? this.login : this.password)
    }
  }
}
</script>

<style>
.copy-btn {
  height: 24px;
}
</style>
<template>
  <!-- <div class="container-fluid map pt-6 px-lg-4 px-xs-0"> -->
  <v-container fluid class="px-0 px-sm-2 px-lg-6">
    <v-row>
      <v-col align-self="center" cols="12" sm="7" md="5">
        <v-subheader class="pl-0 mt-2 map__subheader"> проект </v-subheader>
        <h4 class="subheading">
          {{ selectedPrj ? selectedPrj.name : "" }}
        </h4>
        <a
          target="_blank"
          class="map__subheader-link"
          :href="
            selectedPrj ? `https://${selectedPrj.codename}.mapdev.io/` : ''
          "
        >
          <span
            >{{
              selectedPrj ? `https://${selectedPrj.codename}.mapdev.io/` : ""
            }}
            <img src="@/assets/img/external-link.svg" alt=""
          /></span>
        </a>
      </v-col>

      <v-col class="text-right" align-self="center" cols="2" sm="5" md="7">
        <!-- show-pass -->
        <v-btn
          color="orange lighten-1 mr-0 mr-md-3 mr-lg-5"
          @click="showPasswordModal = true"
          :disabled="!selectedPrj || !login || !password"
          >Показать пароль</v-btn
        >
        <v-btn
          :href="
            mapList && mapList.length
              ? host + '/resource/' + mapList[0].resource.id
              : ''
          "
          :disabled="!mapList || !mapList.length"
          target="_blank"
          :loading="loadingLogin"
          color="orange lighten-1"
          @click="showPasswordModal = true"
          class="mt-5 mt-md-0"
        >
          Открыть консоль
        </v-btn>
      </v-col>
    </v-row>

    <!-- ошибка -->
    <v-row v-if="projectNotCreate" align="center" justify="center">
      <v-col class="text-center" cols="12">
        <h1 class="display-1 font-weight-thin mb-4">Проект еще создается</h1>
        <h4 class="subheading">
          Подождите несколько минут, ваш проет скоро будет создан
        </h4>
        <v-progress-circular
          class="ml-4"
          v-show="failGetMapList"
          :size="40"
          color="black"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>

    <!-- ошибка -->
    <v-row v-else-if="error !== null">
      <v-col>
        <div class="alert alert-danger" role="alert">
          {{ error }} <br />
          <!-- если работает таймер на обновление -->
          <span>
            обновление через {{ toUpdate }}
            <v-progress-circular
              class="ml-4"
              v-show="failGetMapList"
              :size="20"
              color="black"
              indeterminate
            ></v-progress-circular>
          </span>
        </div>
      </v-col>
    </v-row>

    <!-- загрузка -->
    <v-row v-else>
      <v-col class="mt-4">
        <div class="preload" v-if="loadMapList">
          <div class="map__inner">
            <v-row>
              <v-col
                v-for="(item, index) in 3"
                :key="index"
                cols="12"
                sm="12"
                md="12"
                lg="4"
              >
                <div class="map__item">
                  <v-skeleton-loader
                    type="card"
                    height="100%"
                    width="100%"
                  ></v-skeleton-loader>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <!-- контент -->
        <div class="map__inner" v-else>
          <v-row>
            <v-col
              v-for="(map, mapIndex) in mapList"
              :key="mapIndex"
              cols="12"
              sm="12"
              md="12"
              lg="4"
            >
              <div class="map__item">
                <a
                  :href="host + '/resource/' + map.resource.id + '/display'"
                  target="_blank"
                >
                  <div
                    class="map__item-image map__item-no-image"
                    :style="{ backgroundImage: 'url(' + map.preview + ')' }"
                    v-if="map.preview !== null"
                  ></div>
                  <div class="map__item-no-image" v-else></div>
                  <p
                    class="white--text text-center"
                    :title="map.resource.display_name"
                  >
                    {{ map.resource.display_name }}
                  </p>
                </a>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-col>
    </v-row>
    <NgwLoginDataVue
      @close="showPasswordModal = false"
      :open="showPasswordModal"
      :login="login"
      :password="password"
    ></NgwLoginDataVue>
  </v-container>
  <!-- </div> -->
</template>

<script>
import axios from "axios";
import { encode } from "js-base64";
import NgwLoginDataVue from "./NgwLoginDataVue.vue";

export default {
  components: { NgwLoginDataVue },
  props: ["prjCodename", "selectedPrj"],
  name: "project-maps",
  data() {
    return {
      loadMapList: true,
      mapList: [],
      mapStatusList: [],
      error: null,
      host: "",
      showControls: [],
      ngwAuth: false,
      showPasswordModal: false,
      login: "",
      password: "",
      loadingLogin: false,
      checkMapListTimer: null,
      failGetMapList: false,
      retryDelay: 60,
      toUpdate: 5,
      secondCounter: null,
      projectNotCreate: false,
    };
  },

  watch: {
    selectedPrj(newVal) {
      // при смене id пространства перезапрашиваем список карт
      if (newVal) {
        this.stopCheckMapList();
        this.ngwSetHost();
        this.ngwSetLogPass();
        this.ngwLogin(false, false);
        this.ngwGetMaps();
      }
    },
  },

  beforeDestroy() {
    this.stopCheckMapList();
  },

  methods: {
    ngwSetLogPass() {
      if (!this.selectedPrj) {
        console.warn(
          "fail set login/password. Selected project is ",
          this.selectedPrj
        );
        return;
      }
      this.login = "administrator";
      this.password = this.selectedPrj.password;
    },

    async ngwLogin(showPasswordModal, openTab) {
      if (showPasswordModal) {
        this.showPasswordModal = true;
      }
      if (!this.selectedPrj) {
        console.warn("fail login. Selected project is ", this.selectedPrj);
        this.ngwAuth = false;
        return;
      }
      try {
        this.loadingLogin = true;
        let res = await this.$apollo.mutate({
          mutation: require("@/graphql/NgwLogin.gql"),
          variables: {
            login: "administrator",
            password: this.selectedPrj.password,
            prefix: this.selectedPrj.codename,
          },
          headers: {
            "Content-Type": "application/json",
          },
        });
        console.log(res);
        this.loadingLogin = false;
        this.projectNotCreate = false;
        if (openTab && res.data.errors === undefined) {
          window.open(res.data.link_ngw_admin.url, "_blank");
        }
      } catch (error) {
        // console.log(JSON.parse(JSON.stringify(error)))
        if (
          error.message === "GraphQL error: the key 'message' was not present"
        ) {
          this.projectNotCreate = true;
        }
        console.error("fail login to ngw", error);
      }
    },

    ngwSetHost() {
      if (process.env.VUE_APP_HOST.indexOf("https") !== -1) {
        this.host = process.env.VUE_APP_HOST.replace(
          "https://",
          "https://" + this.prjCodename + "."
        );
      } else {
        this.host = process.env.VUE_APP_HOST.replace(
          "http://",
          "http://" + this.prjCodename + "."
        );
      }

      // this.host = process.env.VUE_APP_HOST + "/" + this.prjCodename;
    },

    setShowControls(index, val) {
      // показать/скрыть конпки при наведении на блок
      this.$set(this.showControls, index, val);
    },

    ngwGetMaps() {
      this.error = null; // сброс ошибок
      if (!this.selectedPrj) {
        return;
      }
      this.loadMapList = true; // сброс флагов
      this.failGetMapList = false;
      this.mapList.splice(0, this.mapList.length);
      axios
        .get(this.host + "/api/resource/search/?cls=webmap", {
          headers: {
            Authorization: this.ngwGetHeader(),
          },
        })
        .then((response) => {
          console.log(response);
          this.mapList = response.data;
          response.data.forEach((element, index) => {
            let _previewUrl =
              this.host + `/api/resource/${element.resource.id}/preview.png`;
            this.$set(this.mapList[index], "preview", null);
            this.checkImage(_previewUrl).then((hasImg) => {
              // this.mapList[index].preview = hasImg ? _previewUrl : null
              if (hasImg) {
                this.$set(this.mapList[index], "preview", _previewUrl);
              }
            });
            this.showControls.push(false);
          });
          this.loadMapList = false;
          if (this.failGetMapList) {
            this.stopCheckMapList();
          }
        })
        .catch((err) => {
          console.error("fail get map list from ngw. ", err);
          if (err.message === "not a valid json response from webhook") {
            this.error =
              "Если проект был создан только что, то подождите пару минут и попробуйте еще раз";
          }
          this.loadMapList = false;
          this.failGetMapList = true;
          this.startCheckMapList(); // запрос карт еще раз, если не удалось получить
        });
    },

    stopCheckMapList() {
      clearTimeout(this.checkMapListTimer);
      clearInterval(this.secondCounter);
    },

    startCheckMapList() {
      this.toUpdate = this.retryDelay;
      if (this.secondCounter === null) {
        this.secondCounter = setInterval(() => {
          // счетчик секунд. Запускается если не запущен
          this.toUpdate--;
        }, 1000);
      }
      this.checkMapListTimer = setTimeout(() => {
        // перезапрос данных с задержкой
        this.ngwSetLogPass();
        this.ngwLogin(false, false);
        this.ngwGetMaps();
        this.retryDelay += 5; // увеличение интервала обновления
      }, this.retryDelay * 1000);
    },

    async checkImage(url) {
      try {
        console.log("check", url);
        await axios.get(url);
        return true;
      } catch (error) {
        console.log("5051");
        JSON.stringify(error);
        return false;
      }
    },

    ngwGetHeader() {
      let header = "Basic ";
      header += encode("administrator:" + this.selectedPrj.password);
      return header;
    },
  },
};
</script>

<style lang="scss">
.error {
  color: red;
}
.show-pass {
  margin-right: 250px;
}
.open-ngw {
  margin-right: 50px;
}
</style>
